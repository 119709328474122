import React from "react";
import { AxiosError } from "axios";
import useWebservice from "@livepix/components/hooks/useWebservice";
import useFingerprint from "@livepix/components/hooks/useFingerprint";
import useCheckout from "@livepix/components/hooks/useCheckout";
import useEndpoint from "@livepix/components/hooks/useEndpoint";
import useAlerts from "@livepix/components/hooks/useAlerts";
import { ProfileData, SubscribeAction } from "@livepix/sdk-js/types/profile";
import { PaymentIntent, User, Reward } from "@livepix/sdk-js/types/core";
import { Subscription } from "@livepix/sdk-js/types/core";
import { showPopup } from "helpers/popup";
import SetupRewards from "../reward/SetupRewards";
import SubscriptionConflictForm from "./SubscriptionConflictForm";
import SubscriptionForm from "./SubscriptionForm";
import PaymentReceipt from "./PaymentReceipt";
import Loading from "./Loading";

type Props = {
  user?: User;
  profile: ProfileData;
  rewards: Reward[];
  suggestedAmount?: number;
  suggestedPlan?: string;
  onResize: () => void;
  onError: (message: string) => void;
};

export default function SubscriptionSlide({
  user,
  profile,
  rewards,
  suggestedAmount,
  suggestedPlan,
  onResize,
  onError,
}: Props) {
  const alerts = useAlerts();
  const endpoint = useEndpoint();
  const webservice = useWebservice();
  const fingerprint = useFingerprint();
  const checkout = useCheckout();

  const { data: subscriptions, mutate: reloadSubscriptions } = webservice.load<Subscription[]>(
    `/profile/${profile.username}/subscriptions`,
  );

  const [step, setStep] = React.useState<"loading" | "form" | "conflict" | "rewards" | "receipt">("form");

  const [payment, setPayment] = React.useState<PaymentIntent>();
  const [proof, setProof] = React.useState<string>();

  const [username, setUsername] = React.useState<string>();
  const [planId, setPlanId] = React.useState<string>();
  const [recurrence, setRecurrence] = React.useState<string>();
  const [amount, setAmount] = React.useState<number>();

  React.useEffect(() => onResize(), [step]);

  React.useEffect(() => {
    if (!subscriptions) return;

    const hasActiveSubscription = subscriptions.some((subscription) => subscription.status === "Active");

    if (step !== "loading" && step !== "conflict" && hasActiveSubscription) {
      setStep("conflict");
    }
  }, [step, subscriptions]);

  const checkRewards: SubscribeAction = async (
    username: string,
    amount?: number,
    planId?: string,
    recurrence?: string,
  ) => {
    setUsername(username);
    setAmount(amount);
    setPlanId(planId);
    setRecurrence(recurrence);

    if (!user?.username) {
      showPopup(`${endpoint.authUrl("/signup")}?redirect=/close`, "Faça login", 540, 840, () => {
        setStep("loading");

        webservice
          .get("/auth/me")
          .then(() => setStep("rewards"))
          .catch(() => {
            setStep("form");
            onError("É preciso estar logado para enviar uma assinatura");
          });
      });
    } else {
      setStep("rewards");
    }
  };

  const subscribe = async () => {
    setStep("loading");

    try {
      const requester = await fingerprint.getVisitor();

      const data: Record<string, unknown> = { requester, username };

      if (amount) {
        data.amount = Math.round(amount! * 100);
      } else if (planId && recurrence) {
        data.planId = planId;
        data.recurrence = recurrence;
      }

      const response = await webservice.post<{ paymentId: string }>(`/profile/${profile.username}/subscribe`, data, {
        timeout: 30_000,
      });

      checkout.open(
        response.data.paymentId,
        (payment, proof) => {
          setPayment(payment);
          setProof(proof);
          setStep("receipt");
        },
        () => setStep("form"),
      );
    } catch (e: any) {
      if ((e as AxiosError).response?.status === 403) {
        reloadSubscriptions().then(() => setStep("conflict"));
      } else {
        alerts.error(e.response?.data?.message || "Ocorreu um erro inesperado.");
        setStep("form");
      }
    }
  };

  return (
    <>
      {step === "loading" && <Loading />}
      {step === "form" && (
        <SubscriptionForm
          user={user}
          color={profile.color}
          minAmount={profile.products.subscription.minimumAmount}
          suggestedAmount={suggestedAmount}
          suggestedPlan={suggestedPlan}
          plans={profile.plans}
          onSubscribe={checkRewards}
          onResize={onResize}
        />
      )}
      {step === "conflict" && (
        <SubscriptionConflictForm user={user!} profile={profile} subscription={subscriptions![0]} />
      )}
      {step === "rewards" && (
        <SetupRewards
          user={user}
          color={profile.color}
          amount={amount || 0}
          planId={planId}
          rewards={rewards}
          trigger="subscription"
          onContinue={() => subscribe()}
          onResize={onResize}
        />
      )}
      {step === "receipt" && (
        <PaymentReceipt
          profile={profile}
          payment={payment!}
          proof={proof!}
          onResize={onResize}
          onReset={() => setStep("form")}
        />
      )}
    </>
  );
}
