import React from "react";
import styled from "@emotion/styled";
import { TextField, ToggleButton, ToggleButtonGroup, Radio } from "@mui/material";
import MoneyField from "@livepix/components/form/MoneyField";
import { SubscriptionPlan, User } from "@livepix/sdk-js/types/core";
import useAlerts from "@livepix/components/hooks/useAlerts";
import { SubscribeAction } from "@livepix/sdk-js/types/profile";
import ProfileButton from "@livepix/components/profile/ProfileButton";
import Money from "helpers/Money";

const FieldContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)<{ customcolor: string }>`
  width: 100%;
  margin-bottom: 20px;

  button {
    font-size: 14.52px;

    &:hover {
      color: ${(props) => props.customcolor};
    }

    &.Mui-selected {
      background: ${(props) => props.customcolor};
      color: white;

      &:hover {
        background: ${(props) => props.customcolor};
        filter: brightness(110%);
        color: white;
      }
    }
  }
`;

const PlansContainer = styled.div`
  width: 115%;
  margin-bottom: 20px;
`;

const PlanContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 15px 15px 5px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #e8e6e6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const PlanContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5px;
  margin-right: 5px;
`;

const PlanTitle = styled.p`
  margin: 0;
  font-weight: bold;
  line-height: 26px;
`;

const PlanDescription = styled.p`
  margin: 0;
  font-size: 16px;
`;

const PlanPrice = styled.p<{ color: string }>`
  margin: 0;
  font-size: 26px;
  font-weight: 600;
  text-align: right;
  line-height: 24px;
  color: ${(props) => props.color};

  span {
    display: block;
    font-weight: 400;
    font-size: 16px;
    color: #666;
  }

  b {
    display: block;
    margin-bottom: 10px;
    padding: 0 8px 1px;
    background: white;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    background: ${(props) => props.color};
  }
`;

type Props = {
  color: string;
  minAmount: number;
  suggestedAmount?: number;
  suggestedPlan?: string;
  plans: SubscriptionPlan[];
  onSubscribe: SubscribeAction;
  onResize: () => void;
  user?: User;
};

const recurrenceLabels: Record<string, string> = {
  monthly: "mensais",
  quarterly: "trimestrais",
  semiannual: "semestrais",
  yearly: "anuais",
};

const recurrenceDiscount: Record<string, number> = {
  monthly: 0,
  quarterly: 10,
  semiannual: 15,
  yearly: 20,
};

const recurrenceMultiplyer: Record<string, number> = {
  monthly: 1,
  quarterly: 3 * 0.9,
  semiannual: 6 * 0.85,
  yearly: 12 * 0.8,
};

const formatAmount = (amount: number) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(amount);

export default function SubscriptionForm({
  user,
  color,
  minAmount,
  suggestedAmount,
  suggestedPlan,
  plans,
  onSubscribe,
  onResize,
}: Props) {
  const alerts = useAlerts();

  const [username, setUsername] = React.useState<string>(user?.username || "");
  const [planId, setPlanId] = React.useState<string | undefined>(suggestedPlan);
  const [recurrence, setRecurrence] = React.useState<string>("monthly");
  const [amount, setAmount] = React.useState<number>();

  const [usernameError, setUsernameError] = React.useState<string>();

  React.useEffect(() => {
    if (user?.username) setUsername(user.username);
  }, [user?.username]);

  React.useEffect(() => {
    if (suggestedAmount) setAmount(suggestedAmount);
  }, [suggestedAmount]);

  React.useEffect(() => onResize(), [usernameError]);

  const subscribe = () => {
    setUsernameError(undefined);

    if (plans.length === 0 && !amount) return;
    if (plans.length > 0 && !planId) return;

    if (!/^[A-zÀ-ú0-9 ]{0,20}$/.test(username)) {
      setUsernameError("O nome de usuário deve conter apenas letras, números e espaços e ter no máximo 20 caracteres.");
      return;
    }

    if (username.length === 1) {
      setUsernameError("O nome de usuário deve ter 2 ou mais caracteres.");
      return;
    }

    onSubscribe(username, amount, planId, recurrence).catch((e) =>
      alerts.error(e.response?.data?.message || "Ocorreu um erro inesperado."),
    );
  };

  return (
    <>
      <FieldContainer>
        <TextField
          label="Seu nome de usuário"
          variant="outlined"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          inputProps={{ maxLength: 20 }}
          error={!!usernameError}
          helperText={usernameError}
          fullWidth
        />
      </FieldContainer>
      {plans.length === 0 && (
        <FieldContainer>
          <MoneyField
            label="Valor"
            currency="BRL"
            name="amount"
            value={amount}
            onChange={(value) => setAmount(value)}
            helperText={`O valor mínimo é de ${formatAmount(minAmount)}`}
          />
        </FieldContainer>
      )}
      {plans.length > 0 && (
        <>
          <StyledToggleButtonGroup
            customcolor={color}
            value={recurrence}
            onChange={(_, value) => setRecurrence(value)}
            aria-label="Recorrência"
            exclusive
          >
            <ToggleButton value="monthly">Mensal</ToggleButton>
            <ToggleButton value="quarterly">Trimestral</ToggleButton>
            <ToggleButton value="semiannual">Semestral</ToggleButton>
            <ToggleButton value="yearly">Anual</ToggleButton>
          </StyledToggleButtonGroup>
          <PlansContainer>
            {plans
              .sort((a, b) => {
                if (Number(a.amount) < Number(b.amount)) {
                  return -1;
                }

                if (Number(a.amount) > Number(b.amount)) {
                  return 1;
                }

                return 0;
              })
              .map((plan) => (
                <PlanContainer key={plan.id}>
                  <Radio
                    checked={planId === plan.id}
                    onChange={(_, checked) => {
                      if (checked) setPlanId(plan.id);
                    }}
                  />
                  <PlanContent>
                    <PlanTitle>{plan.name}</PlanTitle>
                    <PlanDescription>{plan.description}</PlanDescription>
                  </PlanContent>
                  <PlanPrice color={color}>
                    {recurrenceDiscount[recurrence] > 0 && <b>desconto de {recurrenceDiscount[recurrence]}%</b>}
                    {formatAmount(Money.fromCents(plan.amount) * recurrenceMultiplyer[recurrence])}
                    <span>{recurrenceLabels[recurrence]}</span>
                  </PlanPrice>
                </PlanContainer>
              ))}
          </PlansContainer>
        </>
      )}
      <ProfileButton
        customcolor={color}
        disabled={plans.length > 0 ? !Boolean(planId) : (amount || 0) < minAmount}
        onClick={subscribe}
      >
        Assinar
      </ProfileButton>
    </>
  );
}
