import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { Subscription, User } from "@livepix/sdk-js/types/core";
import { ProfileData } from "@livepix/sdk-js/types/profile";

const ActionButton = styled(Button)`
  width: 100%;
  padding: 15px 0;
  margin-bottom: 10px;
  border: 1px solid #f4f4f4;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

type Props = {
  user: User;
  profile: ProfileData;
  subscription: Subscription;
};

export default function SubscriptionConflictForm({ user, profile, subscription }: Props) {
  const router = useRouter();

  return (
    <>
      <Typography paragraph>
        Você já possui uma assinatura ativa para <b>{profile.name}</b>. Selecione uma opção para continuar.
      </Typography>
      <ActionButton onClick={() => router.push(`/${profile.username}/assinatura/${subscription.id}`)}>
        Renovar assinatura
      </ActionButton>
      {/**
      <ActionButton onClick={() => router.push(`/${profile.username}/assinatura/${subscription.id}/atualizar`)}>
        Alterar plano da assinatura
      </ActionButton>
      */}
      <ActionButton onClick={() => router.push(`/${profile.username}/assinatura/${subscription.id}/cancelar`)}>
        Cancelar assinatura
      </ActionButton>
    </>
  );
}
